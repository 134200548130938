import React, { useState, useEffect } from "react"
import logoImage from "../../images/new-landing/logo.svg"
import RightVectorImage from "../../images/new-landing/right-vector.svg"
import RightBVectorImage from "../../images/new-landing/right-vector-blue.svg"
import Axios from "axios"
import {getAllUrlParams} from "../../utils"
import classNames from "classnames";
import {Link} from "gatsby";
import Logo from "../../images/logo.svg";
import specialOfferBtnImg from "../../images/special-offer-btn.svg";
import specialOfferImg from "../../images/special-offer.svg";
import ReactGA from "react-ga";
import footerLeftVectorImg from "../../images/footer-left-vector.svg"
import footerRightVectorImg from "../../images/footer-right-vector.svg"
const inputCls =
  "form-input w-full py-2 px-3  text-black  focus:outline-none focus:shadow-outline placeholder-gray-6"
export default function Hero({setToggle, toggle, setRegistrationData, isPromoCode, topBannerClick, navItemClick, toggleVideoModal}) {
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const [promo, setPromo] = useState("")

  const [firstnameCls, setfirstnameCls] = useState("")
  const [lastnameCls, setLastnameCls] = useState("")
  const [usernameCls, setUsernameCls] = useState("")
  const [passwordCls, setPasswordCls] = useState("")
  const [passwordConfirmCls, setPasswordConfirmCls] = useState("")
  // const [promoCls, setPromoCls] = useState("")
  useEffect(() => {
    if (typeof window !== "undefined") {
      setPromo(getAllUrlParams().promo_code)
      console.log(getAllUrlParams().email)
      setUsername(getAllUrlParams().email)
    }

    if(isPromoCode){
      // setPromo("30DaysFree")
    }
  }, [])
  const handleSubmit = event => {
    event.preventDefault()
    setfirstnameCls("")
    setLastnameCls("")
    setUsernameCls("")
    setPasswordCls("")
    setPasswordConfirmCls("")
    // setPromoCls("")

    if (!firstname) {
      alert("Please enter  your First name")
      setfirstnameCls("border-red")
      return
    }
    if (!lastname) {
      alert("Please enter  your  Last name")
      setLastnameCls("border-red")
      return
    }

    if (!username) {
      alert("Please enter yourEmail")
      setUsernameCls("border-red")
      return
    }
    setRegistrationData({firstname, lastname, username, promo })
    try {
      document.querySelector('.new-pricing__hero').scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }

  }

  const gotoPricing = () => {
    try {
      document.querySelector('.new-pricing__hero').scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    ReactGA.event({
      category: 'Einvoicing Aus_Try free for 30 days_Button',
      action: 'Einvoicing Aus_Try free for 30 days_Button clicked'
    });
  }

  const accountBookkeeperClick = () => {
    ReactGA.event({
      category: 'Einvoicing Aus_I am an accountant bookkeeper_Button',
      action: 'Einvoicing Aus_I am an accountant bookkeeper_Button clicked'
    });
  }

  const toggleCls = classNames('navbar-toggler',
      { 'collapsed': !toggle }
  )
  const mobileMenuWrapperCls = classNames(
      { 'show': toggle }
  )

  const handleToggleMenu = (e) => {
    setToggle(!toggle)
  }

  const watchVideoModalClick = () => {
    ReactGA.event({
      category: 'Einvoicing Aus_Watch how it works_Widget',
      action: 'Einvoicing Aus_Watch how it works_Widget clicked'
    });
    toggleVideoModal();
  }

  return (
    <>
      <header className="site-header site-header--nofix">
        <div className="container--full">
        <div className="header-inner">
          <nav className="site-nav">
            <div className="logo">
              <Link to="/" ><img src={Logo} alt="luca logo" /></Link>
            </div>

            <div id="site-menu-wrapper">
              <ul className="site-menu">
                <li onClick={() => navItemClick("About Us")}><a href="/about/" className="hover:text-gray-26 mx-8">About us</a></li>
                <li onClick={() => navItemClick("FAQ")}><a href="https://lucaplus.zendesk.com/hc/en-us" className="hover:text-gray-26 mx-8">FAQ</a></li>
                <li onClick={() => navItemClick("Request Demo")}><a href="https://meetings.hubspot.com/ray-wang/meeting-with-luca-plus" className="hover:text-gray-26 mx-8">Request Demo</a></li>
                <li onClick={() => navItemClick("Login")} className="hover:text-gray-26"><a href={`${process.env.GATSBY_APP_URL}/login`} className="mr-8">Login</a></li>
                <li onClick={watchVideoModalClick}>
                  <button className="play-btn focus:outline-none relative z-30 m-0">
                    Watch how it works
                    <svg className="inline ml-2" width="12" height="14" viewBox="0 0 12 14" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M11.5 7.94778C12.1667 7.56288 12.1667 6.60063 11.5 6.21573L1.75 0.586566C1.08333 0.201666 0.25 0.682792 0.25 1.45259V12.7109C0.25 13.4807 1.08333 13.9618 1.75 13.5769L11.5 7.94778Z"
                          fill="white"/>
                    </svg>
                  </button>
                </li>
              </ul>
            </div>
            <div className={toggleCls} onClick={handleToggleMenu}>
              <span className="menu_toggle" >
                <span className="hamburger">
                  <span/>
                  <span/>
                  <span/>
                </span>
                <span className="hamburger-cross">
                  <span/>
                  <span/>
                </span>
              </span>
            </div>
          </nav>
        </div>
      </div>
        <div id="mobile-menu-wrapper" className={mobileMenuWrapperCls}>
          <ul className="mobile-menu">
            <li onClick={() => navItemClick("About Us")}><a href="/about/" className="hover:text-gray-26 mx-10">About us</a></li>
            <li onClick={() => navItemClick("FAQ")}><a href="https://lucaplus.zendesk.com/hc/en-us" className="hover:text-gray-26 mx-10">FAQ</a></li>
            <li onClick={() => navItemClick("Request Demo")}><a href="https://meetings.hubspot.com/ray-wang/meeting-with-luca-plus" className="hover:text-gray-26 mx-10">Request Demo</a></li>
            <li onClick={() => navItemClick("Login")}><a href={`${process.env.GATSBY_APP_URL}/login`} className="mx-10">Login</a></li>
            <li onClick={watchVideoModalClick} className="mx-10">
              <button className="play-btn focus:outline-none relative z-30 m-0">
                Watch how it works
                <svg className="inline ml-2" width="12" height="14" viewBox="0 0 12 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.5 7.94778C12.1667 7.56288 12.1667 6.60063 11.5 6.21573L1.75 0.586566C1.08333 0.201666 0.25 0.682792 0.25 1.45259V12.7109C0.25 13.4807 1.08333 13.9618 1.75 13.5769L11.5 7.94778Z"
                    fill="white"/>
                </svg>
              </button>
            </li>
          </ul>
        </div>
      </header>
      <div className="footer-banner">
        <div className="text">
          Try LUCA Plus FREE for 30 days
        </div>
        <div className="flex">
          <img src={footerLeftVectorImg} />
          <div onClick={topBannerClick} className="btn font-medium">
            Get Started
          </div>
          <img src={footerRightVectorImg} />
        </div>
      </div>
      <a name="create_account" id="create_account"></a>
      <div className="landing-page-hero">
        <div className="landing-page-hero__overlay"></div>
        <div className="landing-page-hero__left_image"></div>
        <div className="container z-20 inner">
          <div className="flex flex-col sm:flex-row items-center justify-around">
            <div className="text-white  inner-left">
              <div className="inner-left__overlay"></div>
              <div className="inner-left__left_image"></div>
              {/*<div className="flex items-center flex-col sm:flex-row pt-5 sm:pt-0">*/}
              {/*  <div className="relative special-offer-btn mr-3">*/}
              {/*    <div onClick={clickOfferBtn}>*/}
              {/*      <img src={specialOfferBtnImg} className="absolute image cursor-pointer"/>*/}
              {/*    </div>*/}
              {/*    <img src={specialOfferImg}/>*/}
              {/*  </div>*/}
              {/*  <div>*/}
              {/*    <button className="play-btn focus:outline-none relative z-30 m-0" onClick={watchVideoModalClick}>Watch how*/}
              {/*      it works*/}
              {/*      <svg className="inline ml-2" width="12" height="14" viewBox="0 0 12 14" fill="none"*/}
              {/*           xmlns="http://www.w3.org/2000/svg">*/}
              {/*        <path*/}
              {/*            d="M11.5 7.94778C12.1667 7.56288 12.1667 6.60063 11.5 6.21573L1.75 0.586566C1.08333 0.201666 0.25 0.682792 0.25 1.45259V12.7109C0.25 13.4807 1.08333 13.9618 1.75 13.5769L11.5 7.94778Z"*/}
              {/*            fill="white"/>*/}
              {/*      </svg>*/}
              {/*    </button>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <h2 className="title mt-5">
                If you got paid 32% faster, you’d be happy too!
              </h2>
              <div className="sm:mt-8 btn-trial">
                <a
                    className="bg-mind flex rounded text-base focus:outline-none cursor-pointer d-flex justify-content-center"
                    onClick={gotoPricing}
                >
                    Try FREE for 30 days
                  <img src={RightVectorImage} className="ml-2 sm:ml-3" alt="right arrow"/>
                </a>
              </div>
              <div className="sm:mt-8 btn-acc-keep">
                <a
                    onClick={accountBookkeeperClick}
                    className="bg-white flex rounded text-base text-mind focus:outline-none cursor-pointer"
                    href="/signup/"
                >
                  I am an Accountant/Bookkeeper
                  <img src={RightBVectorImage} className="ml-2 sm:ml-3" alt="right arrow"/>
                </a>
              </div>
            </div>

            <form
              className="register-form text-white z-30  "
              onSubmit={handleSubmit}
            >
              <div className="card">
                <div className="form-content">
                  <div>
                    <h3 className="text-brand pt-10 text-xl font-bold leading-7">
                      Everything you need for better cash flow & back-office efficiency
                    </h3>
                    <div className="text-gray-19 mt-2 text-sm">
                      Sign Up for a 30 Day Free Trial
                    </div>
                  </div>
                  <div className="lhero-form ">
                  <div className="mt-4">
                    <label className="block " htmlFor="firstName">
                      <input
                        className={`${inputCls} ${firstnameCls}`}
                        id="firstName"
                        type="text"
                        value={firstname || ""}
                        placeholder="First name"
                        onChange={event => {
                          setFirstname(event.target.value)
                        }}
                      />
                    </label>
                  </div>
                  <div className="mt-4">
                    <label className="block " htmlFor="lastname">
                      <input
                        className={`${inputCls} ${lastnameCls}`}
                        id="lastname"
                        type="text"
                        value={lastname || ""}
                        placeholder="Last name"
                        onChange={event => {
                          setLastname(event.target.value)
                        }}
                      />
                    </label>
                  </div>

                  <div className="mt-4">
                    <label className="block " htmlFor="email">
                      <input
                        className={`${inputCls} ${usernameCls}`}
                        id="email"
                        type="text"
                        placeholder="E-mail"
                        value={username || ""}
                        onChange={event => {
                          setUsername(event.target.value)
                        }}
                      />
                    </label>
                  </div>
                  <div className="mt-4">
                    <label className="block " htmlFor="promo">
                      <input
                        className={`${inputCls} ${lastnameCls}`}
                        id="promo"
                        type="text"
                        placeholder="Promo code"
                        value={promo || ""}
                        onChange={event => {
                          setPromo(event.target.value)
                        }}
                      />
                    </label>
                  </div>
                  <div className="mt-6 sm:mt-6 pb-6 sm:pb-0">
                    <input
                      type="submit"
                      value="Sign Me Up"
                      className="bg-mind rounded w-full  text-base register-btn focus:outline-none"
                    />
                  </div>
                </div>
                </div>
                <div className="mt-5 form-join">
                  {/* Join 1,000+ Australian SME's! */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
