import React, { useState } from "react"
import videoImg from "../../images/new-landing/mock-video.jpg"
import videoImage from "../../images/new-landing/video.png"
import QaItem from "./qa-item"
import Video from "../video"
export default function Faq({toggleVideoModal}) {
  const [opens, setOpens] = useState([false, false, false, false])
  return (
    <section className="faq">
      <div className=" flex flex-col flex-col-reverse sm:flex-row justify-between items-center">
        <div className="text-left w-full sm:w-auto flex-1">
          <h2 className="text-2xl font-bold sm:text-left text-center sm:mt-0 mt-8">
            E-invoicing FAQs
          </h2>
          <ul className="faq__list leading-5 text-neutral-3 font-medium">
            {faqs.map(({ title, content }, index) => (
              <QaItem
                title={title}
                content={content}
                key={`item-${index}`}
                index={index}
                setOpens={setOpens}
                opens={opens}
              />
            ))}
          </ul>
        </div>
        <div className="fvideo cursor-pointer" onClick={toggleVideoModal}>
          <span className="overlay" />
          <div className="fvideo__play" />
          <img src={videoImage} alt="video"  />
        </div>
      </div>
    </section>
  )
}

const faqs = [
  {
    title: "Who is behind LUCA Plus?",
    content:
        <div>LUCA Plus is an Australian company based in Melbourne. <br />Developed by Accountants and Technologists who developed a game changing e-invoicing cloud-based platform for SME’s, Bookkeepers, Accountants and Auditors.Receiving its accreditation by the Australian Taxation Office (ATO) offers the security and convenience of the internationally validated PEPPOL system, meaning the risk of fake or otherwise compromised invoices in considerably reduced.LUCA Plus has received broad-reaching recognition including: <br/>-	2019 Finalist Accountancy Software of the Year, Member of the Melbourne Accelerator Program<br/>-	2019 Winner of the EY Foundry program & winner<br/>-	 Alibaba Entrepreneurs Fund/HSBC JUMPSTARTER 2020 Global Pitch Competition</div>,
  },
  {
    title: "What is e-invoicing? Why is it important?",
    content:
        <div><div className='mb-2'>From the ATO website: “Paper and email based invoicing is manually intensive and prone to human error. Electronic invoicing (or e-invoicing) is the direct, electronic exchange of invoices between suppliers’ and buyers’ financial systems.” </div> <div className='mb-2'> So e-invoicing is NOT just an email  or PDF invoice. Imagine your accounting system can ‘talk to’ the accounting system of your clients and suppliers. Now imagine those communications are automatic and encrypted, and you’re getting close to understanding e-invoicing. It’s a payments & billing revolution!</div>  It’s important because it saves your businesses time and money. According to estimates by Deloitte it could result in benefits to the Australian economy of $28 billion over ten years. </div>,
  },

  {
    title: "What does LUCA Plus do that my existing Accoun-<br> ting software can’t?",
    content:
        <div>LUCA Plus creates a ‘live’ link between your accounting software and that of your clients and suppliers. It essentially creates a new ‘network’ which functions as an automated communication channel between payer and payee. This means you can better track progress of payments owed to you, and that your invoices get paid much faster. Which is a big help for managing your cash-flow!</div>,
  },

  {
    title: "What does LUCA Plus see when I connect my<br>existing Accounting software?",
    content:
        <div>LUCA Plus only uses the payments and invoicing data already in your accounting platform. It does not, for instance, access balance sheet, or profit and loss data. By syncing your accounts receivable and accounts payable data, LUCA Plus is able to create the ‘live’ encrypted data link between you and your debtors and creditors. It’s what creates all the magic of the LUCA Plus system. <br/>(It’s important to note that LUCA Plus is an authorised partner of both Xero or MYOB, and that such technology partners must undergo the highest level of scrutiny).</div>,
  },
]
