import React from "react"
import t1 from "../../images/new-landing/t1.svg"
import t2 from "../../images/new-landing/t2.svg"
import t3 from "../../images/new-landing/t3.svg"
import t4 from "../../images/new-landing/t4.svg"
import Partners from "../v2/partners"
import Partner from "../video/partners"
export default function Trusts2() {
  return (
    <section className="partners">
     <Partner/>
      <div className="some-data">
        <span className="bold hidden sm:block">
          Join the 1k+ Australian businesses that have grown with better cash flow & back-office efficiency
        </span>
        <span className="bold block sm:hidden px-5 mb-10">
          1,000+ small businesses in Australia have already signed up including
        </span>
      </div>
      <div className="partner">
        <div className="container">
          <div className="px-14 sm:px-20">
            <Partners />
          </div>
        </div>
      </div>
    </section>
  )
}
