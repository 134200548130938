import React, {useEffect, useState} from "react"
import {useCookies} from "react-cookie";
import ReactGA from "react-ga"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Plans from "../components/new-landing/plans"

import Trusts2 from "../components/new-landing/trusts2"
import "../scss/style.scss"

import Stories from "../components/new-landing/stories"
import Hero from "../components/new-landing/hero"
import Accolades from "../components/new-landing/accolades"
import About from "../components/new-landing/about"
import Faq from "../components/new-landing/faq"
import Why from "../components/new-landing/why"
import Fans from "../components/new-landing/fans"
import Demo from "../components/new-landing/demo"
import Team from "../components/new-landing/team"
import Feats from "../components/new-landing/feats"
import Fuctions from "../components/new-landing/functions"
import Modali, { useModali } from "../components/modal"
import { getAllUrlParams, initializeAnalytics } from "../utils"
import Popup, {useModal} from "../components/popup";
import {GetCode3} from "../components/getcode";
import Layout from "../components/layout";

export default function NewLandingPage(props) {
  const [videoModal, toggleVideoModal] = useModali()
  const [toggle, setToggle] = useState(false)
  const [cookies, setCookie] = useCookies(['signupPagePopup']);
  const [modalOptions, toggleModal] = useModal()
  const [registrationDetails, setRegistrationDetails] = useState(null);
  const { state= {} } = props.location;

  const setRegistrationData = (data) => {
    setRegistrationDetails(data);
  }

  useEffect(() => {
    // setTimeout(openPopup, 10000);
    }, [])

  const topBannerClick = () => {
    ReactGA.event({
      category: 'Einvoicing Aus_30 day_Banner',
      action: 'Einvoicing Aus_30 day_Banner clicked'
    });
    gotoPricing();
  }

  const clickOfferBtn = () => {
    ReactGA.event({
      category: 'Einvoicing Aus_30 day_Widget',
      action: 'Einvoicing Aus_30 day_Widget clicked'
    });
    gotoPricing();
  }

  const gotoPricing = () => {
    setRegistrationDetails({isPromoCode: true})
    try {
      document.querySelector('.new-pricing__hero').scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }

    const openPopup = () => {
      if(!cookies.signupPagePopup){
        ReactGA.event({
          category: 'POP-UP',
          action: 'Open Sign up Page Pop-up'
        });
        toggleModal();
      }
    }

  const navItemClick = (navItem) => {
    ReactGA.event({
      category: 'NAV_ITEM',
      action: `Clicked ${navItem} Nav Item of Home Page`
    });
  }

    return (
    <div className="h-full font-robo new-landingpage">
      <SEO title="E-invoicing Australia | LUCA+ connecting (XERO, MYOB, QuickBooks)" einvoice={true} description="Start your FREE 30 Day Trial today. LUCA+ E-invoicing Australia. Ease your cashflow & save time with automated electronic invoicing." 
      keywords="e-invoicing Australia,myob e-invoicing,xero e-invoicing" />
      <Hero toggle={toggle} setToggle={setToggle} setRegistrationData={setRegistrationData} isPromoCode={state && state.isPromoCode} topBannerClick={topBannerClick} clickOfferBtn={clickOfferBtn} navItemClick={navItemClick} toggleVideoModal={toggleVideoModal} />
      <section className="bg-white2 bg-white sm:hidden pad-block"></section>
      <Trusts2 />
      <Plans registrationDetails={{...registrationDetails, isPromoCode: true}} page="Einvoicing Aus"/>
      <Why toggleVideoModal={toggleVideoModal} />
      <Fans />
      <Demo />
      <Accolades />
      <Team />
      <Footer simple={true} page="Einvoicing Aus" />
      <Modali.Modal {...videoModal}>
        <div className="hero-video">
            <iframe
                src="https://www.youtube.com/embed/L8Q7ScsjLtQ?autoplay=1&enable_js=1"
                title="Introduction to LUCA+"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
            />
        </div>
      </Modali.Modal>
      <Popup.Modal {...modalOptions}>
        <GetCode3 close={toggleModal} />
      </Popup.Modal>
    </div>
  )
}
