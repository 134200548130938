import React from "react"
import AdvancePlan from "../new-pricing/ad-plan2"
import Plans from "../new-pricing/plans"
export default function Plan({registrationDetails, page, disabledSoloPlanBtn}) {
  return (
    <div className="newlanding__plan new-pricing__hero">
      <div className="t1 mx-auto">
        Unlimited e-invoicing and transaction audit trails on all plans
      </div>

      <div className="desc mt-4 mx-auto">
        Whether you are a sole trader or operating an SME, we have a plan for you. We've set up simple,
        flexible pricing options customised for your business needs
      </div>
      <Plans registrationDetails={registrationDetails} page={page} disabledSoloPlanBtn={disabledSoloPlanBtn} />
      <AdvancePlan page={page} />
    </div>
  )
}
